<template>
  <div class="popup_sub_container">
    <h2>VIEW / EDIT CARRIER </h2>
    <i class="closeme" @click="closeModal">X</i>
    <section>
      <div class="jobs_table sp">
        <div class>
          <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
            <section>
              <div class="form_section1">
                <table>
                  <tr>
                    <td>
                      <ValidationProvider name="name" rules="required" v-slot="{ errors }" slim>
                        <div class="label_and_element_wrapper">
                          <label>
                            Carrier Name
                            <span>*</span>
                          </label>
                          <input type="text" placeholder="Name Character Limit 30" v-model="provider.name"
                            :maxlength="max" :class="errors[0] ? 'warning-border' : ''" :disabled="!is_editable" />
                        </div>
                      </ValidationProvider>
                    </td>

                    <td>
                      <ValidationProvider name="name" rules="required" v-slot="{ errors }" slim>
                        <div class="label_and_element_wrapper">
                          <label>
                            Carrier Code
                            <span>*</span>
                          </label>
                          <input type="text" placeholder="Name Character Limit 30" v-model="provider.carrier_code"
                            :maxlength="max" :class="errors[0] ? 'warning-border' : ''" :disabled="!is_editable" />
                        </div>
                      </ValidationProvider>
                    </td>
                  </tr>

                  <tr>
                    <td>

                      <div class="label_and_element_wrapper">
                        <label>
                          Transit Type
                        </label>
                        <select v-model="provider.transit_type" :disabled="!is_editable">
                          <option value="">Select Transit Type</option>
                          <option value="postcodes">Post Codes</option>
                          <option value="city">City</option>
                          <option value="state">State</option>
                          <option value="country">Country</option>
                        </select>
                      </div>

                    </td>

                    <td>
                      <div class="label_and_element_wrapper">
                        <label>
                          Default Transit Days
                        </label>
                        <input type="text" placeholder=" Default Transit Days" v-model="provider.default_transit_days"
                          :maxlength="max2" :disabled="!is_editable" />
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td class="rowspanned_4_chk">
                      <!--  <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim> -->
                      <div class="label_and_element_wrapper">
                        <label>
                          Carrier Service Name
                          <span>*</span>
                        </label>
                        <div class="chk_group">
                          <label v-for="(service, i) in provider.services" :key="'service' + i">
                            <input type="checkbox" v-model="service.checked" :true-value="service.id" :false-value="false" :disabled="!is_editable" />
                            <span>{{ service.code }} - {{ service.name }} - {{ service.priority }}</span>
                          </label>
                        </div>
                      </div>
                      <!-- </ValidationProvider> -->
                    </td>

                    <td class="rowspanned_4_chk">
                      <!--  <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim> -->
                      <div class="label_and_element_wrapper">
                        <label>
                          Carrier Service Type
                          <span>*</span>
                        </label>
                        <div class="chk_group">
                          <label v-for="(type, i) in provider.servicetypes" :key="'type' + i">
                            <input type="checkbox" v-model="type.checked" :true-value="type.id" :false-value="false"
                              :disabled="!is_editable" />
                            <span>{{ type.name }}</span>
                          </label>
                        </div>
                      </div>
                      <!-- </ValidationProvider> -->
                    </td>
                  </tr>

                  <tr>
                    <td class="rowspanned_4_chk">

                      <div class="label_and_element_wrapper">
                        <label>
                          Mode of Dispatch
                          <span>*</span>
                        </label>
                        <div class="chk_group">
                          <label v-for="(mode, i) in provider.dispatches" :key="'dispatch' + i">
                            <input type="checkbox" v-model="mode.checked" :true-value="mode.id" :false-value="false"
                              :disabled="!is_editable" />
                            <span>{{ mode.name }}</span>
                          </label>
                        </div>
                      </div>


                    </td>

                    <td class="rowspanned_4_chk">
                      <!--  <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim> -->
                      <div class="label_and_element_wrapper">
                        <label>
                          Type of Goods
                          <span>*</span>
                        </label>
                        <div class="chk_group">
                          <label v-for="(good, i) in provider.goods" :key="'good' + i">
                            <!-- :class="errors[0] ? 'warning-border':''" -->
                            <input type="checkbox" v-model="good.checked" :true-value="good.id" :false-value="false"
                              :disabled="!is_editable" />
                            <span>{{ good.name }}</span>
                          </label>
                        </div>
                      </div>
                      <!--  </ValidationProvider> -->
                    </td>
                  </tr>

                  <tr>
                    <td class="rowspanned_4_chk">
                      <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
                        <div class="label_and_element_wrapper">
                          <div class="chk_group" style="height: 73px;overflow:hidden;">
                            <label :class="errors[0] ? 'warning-border' : ''" style="background: none;">
                              <input type="checkbox" v-model="provider.is_generate_carrier_slip_hub"
                                :disabled="!is_editable" true-value="1" false-value="0" />
                              <span style="font-size: 14px;"> Generate Carrier Slip from Hub</span>
                            </label>
                          </div>
                        </div>
                      </ValidationProvider>
                    </td>


                    <td class="rowspanned_4_chk">
                      <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
                        <div class="label_and_element_wrapper">
                          <div class="chk_group" style="height: 73px;overflow:hidden;">
                            <label :class="errors[0] ? 'warning-border' : ''" style="background: none;">
                              <input type="checkbox" v-model=provider.is_dispatch_manifest_hub :disabled="!is_editable"
                                true-value="1" false-value="0" />
                              <span style="font-size: 14px;"> Dispatch Manifest from Hub</span>
                            </label>
                          </div>
                        </div>
                      </ValidationProvider>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div class="label_and_element_wrapper">
                        <label>
                          Dg
                        </label>
                        <input type="checkbox" name="dg" v-model="provider.is_dg" true-value="1" false-value="0"
                          :disabled="!is_editable" />
                        <label style="right: -69px;position: relative;top: -60px;">
                          Dg Information
                        </label>
                        <input type="text" placeholder="Enter Dg Information" v-model="provider.dg_information"
                          :disabled="provider.is_dg == 0" :maxlength="max2"
                          style="width: 90%;float: right;top: -62px;position: relative;">
                      </div>
                    </td>


                    <td>
                      <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
                        <div class="label_and_element_wrapper">
                          <label>
                            Carrier Account
                            <span>*</span>
                          </label>
                          <input type="text" placeholder="Name Character Limit 30"
                            :class="errors[0] ? 'warning-border' : ''" v-model="provider.carrier_account" :maxlength="max2"
                            :disabled="!is_editable" />
                        </div>
                      </ValidationProvider>
                    </td>
                  </tr>

                  <tr>
                    <td style="display: flex;">
                      <div class="_sur_flex_boxes">
                        <div class="label_and_element_wrapper" >
                          <label>
                            Inbound ?
                          </label>
                          <input name="available" type="checkbox"  v-model="provider.inbound_provider" true-value="1" false-value="0"  /> 
                        </div>
                      </div> 
                   
                      <div class="_sur_flex_boxes">
                        <div class="label_and_element_wrapper" >
                          <label>
                            Outbound ?
                          </label>
                          <input name="available" type="checkbox" v-model="provider.outbound_provider" true-value="1" false-value="0"  /> 
                        </div>
                      </div>

                      <div class="_sur_flex_boxes">
                        <div class="label_and_element_wrapper" >
                          <label>
                            For user?
                          </label>
                          <input name="available" type="checkbox" v-model="provider.is_user_selection_need" true-value="1" false-value="0"  /> 
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <!--  <td>
                  <div class="label_and_element_wrapper">
                  <label>
                  Preferred
                  </label>
                  <input name="available" type="checkbox" v-model="provider.is_carrier_preference"  true-value="1" false-value="0" :disabled="!is_editable"/>
                  </div>
                  <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                  <label style="right: -69px;position: relative;top: -70px;">
                  Rating
                  <span>*</span>
                  </label>
                  <input type="text" placeholder="Enter Rating"  :class="errors[0] ? 'warning-border':''" v-model="provider.sell_rate_margin" :maxlength="max2" style="width: 90%;float: right;top: -69px;position: relative;" :disabled="!is_editable">
                  </div>
                  </ValidationProvider>
                </td> -->

                    <td>
                      <div class="label_and_element_wrapper">
                        <label>
                          Preferred
                        </label>
                        <input name="available" type="checkbox" v-model="provider.is_carrier_preference" true-value="1"
                          false-value="0" :disabled="!is_editable" />
                      </div>

                      <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>

                        <div class="label_and_element_wrapper" style="margin-top: -71px;margin-left: 112px;">
                          <label style="right: 39px;position: relative;top: 1px;">
                            Rating
                            <span>*</span>
                          </label>
                        </div>

                        <div class="newdiv" style="margin-left: 88px;margin-top: -10px;position: relative;">
                          <input type="text" placeholder="Enter Rating" v-model="provider.sell_rate_margin"
                            style="width:15%;height: 10px;padding: 5px 20px;height: 40px;background: #fff;border: 1px solid #ccc;">
                        </div>
                      </ValidationProvider>

                      <div class="label_and_element_wrapper"
                        style="margin-top: -77px;margin-left: 162px;position: relative;left:-1%;">
                        <label>
                          Receiver Duty Paid
                        </label>
                        <input name="available" type="checkbox" v-model="provider.is_duty_paid_by_shipper" true-value="1"
                          false-value="0" style="position: relative;top: 0%;" />
                      </div>

                      <div class="label_and_element_wrapper"
                        style="margin-top: -70px;margin-left: 224px;position: relative;left:9%;">
                        <label>
                          Regular Pickup
                        </label>
                        <input name="available" type="checkbox" v-model="provider.is_scheduled_pickup_api" true-value="1"
                          false-value="0" style="position: relative;
         top: 0%;" />
                      </div>

                      <div class="label_and_element_wrapper" style="margin-top: -68px;float:right;">
                        <label>
                          Generate Label Api
                        </label>

                        <input name="available" type="checkbox" v-model="provider.is_generate_label_api" true-value="1"
                          false-value="0" style="position: relative;
                top: 0%;" />
                      </div>


                    </td>


                    <td>
                      <div class="label_and_element_wrapper">
                        <label>
                          Dispatched Api
                        </label>
                        <input name="available" type="checkbox" v-model="provider.is_available" true-value="1"
                          false-value="0" :disabled="!is_editable" />
                      </div>

                      <div class="label_and_element_wrapper" style="margin-top: -71px;margin-left: 112px;">
                        <label>
                          Markup Margin
                          <span>*</span>
                        </label>
                      </div>

                      <div class="newdiv" style="margin-left: 130px;margin-top: -10px;position: relative;">
                        <label class="radio-inline">
                          Percentage
                        </label>
                        <input type="radio" name="provider.markup_margin_type" @change="onChangeMarkupMargin($event)"
                          :checked="provider.markup_margin_type == 'per'" value="per"
                          style="margin: 0 15px;position: relative;" :disabled="!is_editable">
                        <input type="text" placeholder="Enter" v-model="provider.markup_margin_per"
                          :disabled="provider.markup_margin_type == 'fix' || !is_editable"
                          style="width: 20%;height: 10px;padding: 5px 20px;height: 40px;background: #fff;border: 1px solid #ccc;">

                        <label class="radio-inline" style="margin: 0 0 0 15px;">
                          Fixed
                        </label>
                        <input type="radio" name="provider.markup_margin_type" @change="onChangeMarkupMargin($event)"
                          :checked="provider.markup_margin_type == 'fix'" value="fix"
                          style="margin: 0 15px;position: relative;" :disabled="!is_editable">
                        <input type="text" placeholder="Enter" v-model="provider.markup_margin_fix"
                          :disabled="provider.markup_margin_type == 'per' || !is_editable"
                          style="width: 20%;height: 10px;padding: 5px 20px;height: 40px;background: #fff;border: 1px solid #ccc;">
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <ValidationProvider name="description" rules="required" v-slot="{ errors }" slim>
                        <div class="label_and_element_wrapper">
                          <label>
                            Description
                            <span>*</span>
                          </label>
                          <textarea placeholder="Type Here Carrier Description" :class="errors[0] ? 'warning-border' : ''"
                            v-model="provider.description" style="width: 203%;" :disabled="!is_editable"></textarea>
                        </div>
                      </ValidationProvider>
                    </td>
                  </tr>

                </table>

                <table>
                  <tr>
                    <td>
                      <ValidationProvider name="time_from" rules="required" v-slot="{ errors }" slim>
                        <div class="label_and_element_wrapper">
                          <label>
                            Window Time From
                            <span>*</span>
                          </label>
                          <flat-pickr :config="configTime" placeholder="From Time" name="time_from"
                            v-model="time_from"></flat-pickr>
                        </div>
                      </ValidationProvider>
                      <ValidationProvider name="time_to" rules="required" v-slot="{ errors }" slim>
                        <div class="label_and_element_wrapper">
                          <label>
                            Window Time To
                            <span>*</span>
                          </label>
                          <flat-pickr :config="configTime" placeholder="To Time" name="time_to"
                            v-model="time_to"></flat-pickr>
                        </div>
                      </ValidationProvider>
                    </td>
                  </tr>
                </table>

                <table>

                  <tr>
                    <td>
                      <ValidationProvider name="logo" rules="required" v-slot="{ errors }" slim>
                        <div class="label_and_element_wrapper">
                          <label>
                            Upload Logo

                          </label>
                          <label class="user_profile_upload ">
                            <span>Choose a File</span>
                            <span style="float: right; color: #767272;font-size: 14px;">Size 150*75</span>
                            <input type="file" ref="logo" @change="uploadFile" accept="image/*"
                              :class="errors[0] ? 'warning-border' : ''" />
                            <span v-if="logo != ''">
                              {{ logoname }}
                            </span>
                          </label>
                        </div>
                      </ValidationProvider>
                    </td>


                    <td>
                      <ValidationProvider name="terms" rules="required" v-slot="{ errors }" slim>
                        <div class="label_and_element_wrapper">
                          <label>
                            Upload Special Notes
                            <!-- <span>*</span> -->
                          </label>
                          <label class="user_profile_upload">
                            <span>Choose a File</span>
                            <span style="float: right; color: #767272;font-size: 14px;">PDF Format Recommended</span>
                            <input type="file" ref="sla" @change="uploadSLADocument" accept=".pdf"
                              :class="errors[0] ? 'warning-border' : ''" />
                            <span v-if="sla != ''">
                              {{ sla_name }}
                            </span>
                          </label>
                        </div>
                      </ValidationProvider>
                    </td>


                    <td>
                      <ValidationProvider name="logo" rules="required" v-slot="{ errors }" slim>
                        <div class="label_and_element_wrapper">
                          <label>
                            Upload Terms & Conditions
                            <!--  <span>*</span> -->
                          </label>
                          <label class="user_profile_upload ">
                            <span>Choose a File</span>
                            <span style="float: right; color: #767272;font-size: 14px;">PDF Format Recommended</span>
                            <input type="file" ref="terms" @change="uploadTerms" accept=".pdf"
                              :class="errors[0] ? 'warning-border' : ''" />
                            <span v-if="terms != ''">
                              {{ terms_name }}
                            </span>
                          </label>
                        </div>
                      </ValidationProvider>
                    </td>
                  </tr>

                </table>



              </div>
            </section>
          </ValidationObserver>

          <section class="centered">
            <a class="link_bt bt_cancel" @click="closeModal">CANCEL</a>
            <a class="link_bt bt_save" @click="updateCarrier" :disabled="update_btn.disabled"
              v-if="is_editable">{{ update_btn.value }}</a>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment-timezone";

export default {
  name: "EditCarrier",
  components: {
    flatPickr,
  },
  created() {
    this.fetchUnit();

  },
  data() {
    return {
      max: 30,
      max2: 60,
      max1: 10,
      max3: 20,
      dispatches: [],
      servicetypes: [],
      services: [],
      goods: [],
      mode_of_dispatch: [],
      optradio: '',
      provider: [],
      logo: '',
      logoname: '',
      terms: '',
      terms_name: '',
      sla: '',
      sla_name: '',
      update_btn: {
        disabled: false,
        value: "UPDATE"
      },
      configTime: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
      },
      time_from: moment().format("HH:mm:ss"),
      time_to: moment().format("HH:mm:ss"),
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    fetchUnit() {
      this.axios.get("api/provider/view/providerId/" + this.provider_id)
        // .then(response => {
        .then(async (response) => {
          let mode_of_dispatch = await this.axios.get("api/dispatch");
          let servicesArray = await this.axios.get("api/service");
          let goodsArray = await this.axios.get("api/goods");
          let servicetypeArray = await this.axios.get("api/common/servicetype");
          this.provider = response.data.providers;

          this.provider.dispatches = mode_of_dispatch.data.mode_of_dispatch.map(mode => {
            return {
              id: mode.id,
              name: mode.name,
              checked: this.provider.modes.find(dispatchelement => dispatchelement.id == mode.id) ? mode.id : false
            }
          });

          this.provider.services = servicesArray.data.services.map(sname => {
            return {
              id: sname.id,
              name: sname.name,
              code: sname.code,
              priority: sname.priority,              
              checked: this.provider.servicenames.find(servicelement => servicelement.id == sname.id) ? sname.id : false
            }
          });

          this.provider.servicetypes = servicetypeArray.data.servicetype.map(type => {
            return {
              id: type.id,
              name: type.service_type,
              checked: this.provider.servicetypes.find(typelement => typelement.id == type.id) ? type.id : false
            }
          });

          this.provider.goods = goodsArray.data.goods.map(good => {
            return {
              id: good.id,
              name: good.type,
              checked: this.provider.carriergoods.find(goodelement => goodelement.id == good.id) ? good.id : false
            }
          });

          if (this.provider.markup_margin_type == 'per') {
            var optionText = 0;
            this.provider.markup_margin_per = this.provider.markup_margin;
            this.provider.markup_margin_fix = '';
          }
          if (this.provider.markup_margin_type == 'fix') {
            var optionText = 1;
            this.provider.markup_margin_fix = this.provider.markup_margin;
            this.provider.markup_margin_per = '';
          }

          if (response.data.providers.window_schedule) {
            this.time_from = response.data.providers.window_schedule.from;
            this.time_to = response.data.providers.window_schedule.to;
          }
          
        })
        .catch(error => {
          console.log(error);
        })
    },
    uploadFile() {
      this.logo = this.$refs["logo"].files[0];
      console.log('this.logo');
      console.log(this.logo);
      this.logoname = this.logo.name;
    },
    uploadSLADocument() {
      this.sla = this.$refs["sla"].files[0];
      this.sla_name = this.sla.name;
    },
    uploadTerms() {
      this.terms = this.$refs["terms"].files[0];
      this.terms_name = this.terms.name;
    },
    onChangeMarkupMargin(event) {
      //console.log(optionText);
      var optionText = event.target.value;
      if (optionText == "per") {
        this.provider.markup_margin_type = "per";
        this.provider.markup_margin_per = '';
      }
      if (optionText == "fix") {
        this.provider.markup_margin_type = "fix";
        this.provider.markup_margin_fix = '';
      }
    },
    updateCarrier(carrier) {
      this.provider.markup_margin = this.provider.markup_margin_per != '' ? this.provider.markup_margin_per : this.provider.markup_margin_fix;

      let formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("name", this.provider.name);
      formData.append("carrier_code", this.provider.carrier_code);
      formData.append("description", this.provider.description);
      formData.append("is_available", this.provider.is_available);
      formData.append("markup_margin", this.provider.markup_margin);
      formData.append("markup_margin_type", this.provider.markup_margin_type);
      formData.append("is_dg", this.provider.is_dg);
      formData.append("dg_information", this.provider.dg_information);
      formData.append("is_carrier_preference", this.provider.is_carrier_preference);
      formData.append("sell_rate_margin", this.provider.sell_rate_margin);
      if (this.logo)
        formData.append("logo", this.logo);
      if (this.terms)
        formData.append("terms", this.terms);
      if (this.sla)
        formData.append("sla", this.sla);
      formData.append("transit_type", this.provider.transit_type);
      formData.append("is_generate_carrier_slip_hub", this.provider.is_generate_carrier_slip_hub);
      formData.append("is_dispatch_manifest_hub", this.provider.is_dispatch_manifest_hub);
      formData.append("carrier_account", this.provider.carrier_account);
      formData.append("default_transit_days", this.provider.default_transit_days);
      formData.append("is_scheduled_pickup_api", this.provider.is_scheduled_pickup_api);
      formData.append("is_generate_label_api", this.provider.is_generate_label_api);
      formData.append("inbound_provider", this.provider.inbound_provider);
      formData.append("outbound_provider", this.provider.outbound_provider);
      formData.append("is_user_selection_need", this.provider.is_user_selection_need);
      formData.append("is_duty_paid_by_shipper", this.provider.is_duty_paid_by_shipper);

      this.provider.dispatches.forEach(element => {
        if (element.checked)
          formData.append("mode_of_dispatch[]", element.id);
      });
      this.provider.services.forEach(element => {
        if (element.checked)
          formData.append("carrier_service_name[]", element.id);
      });
      this.provider.servicetypes.forEach(element => {
        if (element.checked)
          formData.append("carrier_service_type[]", element.id);
      });

      this.provider.goods.forEach(element => {
        if (element.checked)
          formData.append("type_of_good[]", element.id);
      });

      formData.append("time_from", this.time_from);
      formData.append("time_to", this.time_to);
      console.log(formData);

      this.axios
        .post("/api/provider/" + this.provider.id, formData)
        .then(response => {
          this.toast.success(response.data.msg);
          // this.is_editable = false;
          this.$emit("closeModal");
        })
        .catch(error => {
          console.log(error);
        });
    },
  },
  props: {
    provider_id: String,
    is_editable: {
      type: Boolean,
      default: true
    }
  }
};
</script>
<style lang="scss">
.popup_sub_container {
  padding: 20px;
  width: 100%;
  margin: auto;

  background: #fff;
}

.popup_sub_container>section {
  padding: 20px 0 0 0;
}

h3.sub_head {
  font-size: 20px;
  font-weight: 600;
  padding: 0;
}

.preferred_hide {
  display: none;
}

.chk_prefered {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin: 0 20px;
}

.chk_prefered:checked+hr+.preferred_hide {
  display: block;
}

.title_image {
  display: block;
  margin: auto;
}

.form_section1 {
  width: 100%;
  height: auto;
  padding: 20px;
  background: #ececec;
  margin: 20px 0;
}

i.closeme {
  width: 30px;
  height: 30px;
  background: #000;
  color: #06A5ED;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  border-radius: 100px;
  display: block;
  font-style: normal;
  font-weight: bold;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
</style>
<style scoped>
.jobs_table table {
  border: 0;
}

.jobs_table table tr td {
  padding: 0;
  border: 0;
}

.user_profile_upload {
  display: block;
  border: 0;
  height: 40px;
  width: 200px;
  background: #004a7c;
  color: #fff;
  font-size: 18px;
  margin-right: 10px;
  position: relative;
  margin-bottom: 30px;
}

.user_profile_upload input {
  opacity: 0;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.user_profile_upload span {
  display: block;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 30px;
  text-align: center;
  color: #fff;
  font-weight: normal;
}</style>